@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
  h1 {
    @apply text-xl font-medium text-character;
  }

  h2 {
    @apply text-xl font-medium text-character;
  }

  h3{
    @apply text-base font-semibold text-character;
  }

  p {
    @apply text-base text-character;
  }

  :root {
    --color-page: 230 230 230;
    --color-large: 14 19 24;
    --color-medium: 13 19 23;
    --color-primary: 240 250 250;
    --color-secondary: 215 215 215;
    --color-tertiary: 220 248 250;
    --color-character: 0 0 0;
    --color-ambient: 82 227 162;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --color-page: 9 10 15;
      --color-large: 15 23 42;
      --color-medium: 13 19 23;
      --color-primary: 17 24 39;
      --color-secondary: 44 58 74;
      --color-tertiary: 35 38 46;
      --color-character: 180 180 180;
      --color-ambient: 36 22 69;
    }
  }
}

@layer components{
  .card-box {
    @apply p-4 bg-tertiary rounded-xl shadow-lg flex items-center space-x-4;
  }  
}


@layer utilities {
  .animation-fade-in {
    opacity: 0;
    animation: fade-in 0.35s ease-in forwards;
  }
  
  @keyframes fade-in {
    100% {
      transform: translateY(0);
      opacity: 1;
    }

    0% {
      transform: translateY(5%);
      opacity: 0;
    }
  }

  .animation-bg-fade {
    opacity: 0;
    animation: fade-in-bg 0.35s ease-in forwards;
  }

  @keyframes fade-in-bg {
    100% {
      transform: translateY(0);
      opacity: 1;
    }

    0% {
      transform: translateY(2%);
      opacity: 0;
    }
  }

  .animation-fade-in-down{
    opacity: 0;
    animation: fade-in-down 0.35s ease-in forwards;
  }

  @keyframes fade-in-down {
    100% {
      transform: translateY(0);
      opacity: 1;
    }

    0% {
      transform: translateY(-20%);
      opacity: 0;
    }

  }

  .page-column {
    @apply mx-auto md:max-w-md max-w-xs space-y-12 mt-36 pb-28;
  }

  .page-background{
    @apply inset-0 -z-10 h-full w-full px-5 pt-12 bg-radial-gradient-top animation-bg-fade;
  }
}